<template>
  
 


    <!-- Hero Area Start -->
    <section id="hero-area" class=" pt-10 pb-10">
      <div class="container mx-auto">
        <div class="flex justify-between">
          <div class="w-full text-center">
            
            <div class="flex justify-center items-center mb-10">
              <img alt="logo" src="./assets/logo.png" style="width: 200px;">
            </div>
            
            <h3 class="text-3xl sm:text-1xl md:text-3xl lg:text-3xl font-bold leading-snug text-gray-700 mb-10 wow fadeInUp" data-wow-delay="1s">
              We supply exceptional and superior quality 
              <br class="hidden sm:block lg:block"> concrete bricks and blocks
              <br class="hidden sm:block lg:block"> for your construction projects.
            </h3>

            <h3 class="text-1xl font-bold uppercase text-red-600 mb-10 wow fadeInUp" data-wow-delay="1s">
              Free Delivery in Ramotswa and surrounding villages
            </h3>
            
            <div class="text-center wow fadeInUp" data-wow-delay="1.6s">
              <img class="img-fluid mx-auto" src="assets/img/hero.svg" alt="">
            </div>
          
          </div>
        </div>
      </div>
    </section>
    <!-- Hero Area End -->


    <!-- Pricing section Start --> 
    <section id="pricing" class="bg-gray-300 p-10">
      <div class="container mx-auto">

        <div class="text-center">
          <h2 class="mb-12 text-4xl text-gray-700 font-bold tracking-wide wow fadeInDown" data-wow-delay="0.3s">Our Products</h2>
        </div>

        <div class="flex flex-wrap justify-center md:justify-start bg-gray-300" >
          <!-- single pricing table starts -->
          <div class="w-3/4 sm:w-2/3 md:w-1/2 lg:w-1/3  text-center">
            <div class="pricing-box wow fadeInLeft" data-wow-delay="1.2s">
              <div class="mb-3">
                <h3 class="package-name">STANDARD 6" HOLLOW BLOCKS</h3>
              </div>
              <div class="mb-5">
                <p class="text-gray-700">
                  <span class="font-bold text-2xl">BWP 8.50</span>
                  <span class="font-medium text-sm"> Each</span>
                </p>

                <p>Great for external house walls and boundry walls...</p>
              </div>
            </div>
          </div>

          <!-- single pricing table ends -->
          <!-- single pricing table starts -->

          <div class="w-3/4 sm:w-2/3 md:w-1/2 lg:w-1/3 w-full text-center bg-gray-300">
            
            <div class="pricing-box wow fadeInLeft" data-wow-delay="1.2s">
              <div class="mb-3">
                <h3 class="package-name">STANDARD 4 &#189;" HOLLOW BLOCKS</h3>
              </div>
              <div class="mb-5">
                <p class="text-gray-700">
                  <span class="font-bold text-2xl">BWP 8.00</span>
                  <span class="font-medium text-sm"> EACH</span>
                </p>

                <p>Exceptional for internal deviding walls...</p>
              </div>
              
            </div>

          </div>
          <!-- single pricing table ends -->
          <!-- single pricing table starts -->
          <div class="w-3/4 sm:w-2/3 md:w-1/2 lg:w-1/3 w-full text-center bg-gray-300">
            <div class="pricing-box">
              <div class="mb-3">
                <h3 class="package-name">STANDARD STOCK SOLID BLOCKS</h3>
              </div>
              <div class="mb-5">
                <p class="text-gray-700">
                  <span class="font-bold text-2xl">BWP 2.50</span>
                  <span class="font-medium text-sm"> Each</span>
                </p>
                <p>Ideal for strong foundations and walls...</p>
              
              </div>
              
            </div>
          </div>
          <!-- single pricing table ends -->
        </div>
      </div>
    </section>
    <!-- Pricing Table Section End -->





     <!-- Contact Section Start -->
    <section id="contact" class="p-10">    
      <div class="container mx-auto">
        <div class="text-center">
          <h2 class="mb-12 text-4xl text-gray-700 font-bold tracking-wide wow fadeInDown" data-wow-delay="0.3s">Contact Us</h2>
        </div>

        <div class="flex flex-wrap justify-center md:justify-start">
          <div class="w-3/4 sm:w-2/3 md:w-1/2 lg:w-1/2 w-full text-center">
          <!-- single pricing table starts -->
          <!-- <div class="w-1/2 sm:w-2/3 md:w-1/2 lg:w-1/2 w-full  text-center"> -->
            <div class="pricing-box wow fadeInLeft" data-wow-delay="1.2s">
              <div class="mb-3">
                <h3 class="package-name">We are based in</h3>
              </div>
              <div class="mb-5">
                <p class="text-gray-700">
                  <span class="font-medium text-sm">Ramotswa</span><br />
                  <span class="font-bold text-2xl uppercase text-red-600">Siga Industrial Site</span>
                  
                </p>

              </div>
            </div>
          </div>

          <!-- <div class="w-1/2 sm:w-2/3 md:w-1/2 lg:w-1/2  text-center"> -->

          <div class="w-3/4 sm:w-2/3 md:w-1/2 lg:w-1/2 w-full text-center ">
            <div class="pricing-box wow fadeInLeft" data-wow-delay="1.2s">
              <div class="mb-3">
                <h3 class="package-name">We reachable</h3>
              </div>
              <div class="mb-5">
                <p class="text-gray-700">
                  <span class="font-medium text-sm">For more information at</span><br />
                  <span class="font-bold text-2xl uppercase text-red-600">+267 75722623</span>
                  
                </p>

              </div>
            </div>
          </div>
         
        </div>

        

      </div> 
    </section>
  

    <section id="google-map-area">
        <div class="" >

          <div class="flex">
              <div class="w-full" style="height:600px;">

                <l-map ref="map" v-model:zoom="zoom" :center="[-24.88777543687244, 25.868275788932657]">
                  <l-marker :lat-lng="[-24.88777543687244, 25.868275788932657]">
                    <l-icon
                      :iconSize="[60, 40]"
                      :icon-anchor="[50, 50]"
                      icon-url="img/icons/logo.png"
                    />
                  </l-marker>

                  <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    layer-type="base"
                    name="OpenStreetMap"
                    attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  ></l-tile-layer>
                </l-map>    
              
              </div>
          </div>

        
      </div>

    </section>



    <!-- Footer Section Start -->
    <footer id="footer" class="bg-gray-300 py-16">
      <div class="container mx-auto">
        <div class="text-center">
          <p class="text-black">&copy; 2024 Agang Holdings All rights reserved </p>
        </div>
      </div>     
    </footer> 
    <!-- Footer Section End -->










  
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer,LMarker, LIcon } from "@vue-leaflet/vue-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data() {
    return {
      zoom: 20,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    };
  },
};
</script>

<style></style>
